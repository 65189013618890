<template>
  <button
    class="won-export"
    :disabled="isLoading"
    @click="exportWon"
  >
    <template v-if="isLoading">
      {{ $t('LOADING_MSG') }}
    </template>

    <template v-else>
      {{ $t('EXPORT_BTN') }}
    </template>
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { userGetters } from 'Store/entities/User/types'

import { impactWonActions, impactWonGetters } from '../store/types'
import { IMPACT_WON_LIST_TEMPLATES } from '../constants'

import { formatDate } from 'Utils/dateHelpers'
import { downloadCsvFile } from 'Utils/downloadCsvFile'
import { showError } from 'Utils/notifications'

export default {
  name: 'won-export',
  props: {
    template: {
      type: String,
      default: IMPACT_WON_LIST_TEMPLATES.invoices,
      validator (value) {
        return Object.values(IMPACT_WON_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapGetters({
      exportList: `ui/impact-won/${impactWonGetters.EXPORT_LIST}`,
      platformId: `entities/user/${userGetters.PLATFORM_ID}`
    }),

    exportTableHead () {
      const headParts = [this.$t('EXPORT_TABLE_HEAD_BASE')]

      switch (this.template) {
        case IMPACT_WON_LIST_TEMPLATES.invoices:
          headParts.push(this.$t('EXPORT_TABLE_HEAD_INVOICES'))
          break

        case IMPACT_WON_LIST_TEMPLATES.pickups:
          headParts.push(this.$t('EXPORT_TABLE_HEAD_PICKUPS'))
          break
      }

      return headParts.join(',')
    },

    wonBlob () {
      const wonRows = this.exportList.map(item => {
        return [
          item.vehicle.id,
          item.vehicle.name,
          item.vehicle.vin || this.$t('COMMON.SYMBOLS.MDASH'),
          this.getWinnerName(item),
          item.login || this.$t('COMMON.SYMBOLS.MDASH'),
          item.location || this.$t('COMMON.SYMBOLS.MDASH'),
          ...this.getTemplateCells(item)
        ].join(',')
      })

      return [this.exportTableHead, ...wonRows].join('\n')
    }
  },

  methods: {
    ...mapActions('ui/impact-won', {
      loadExportList: impactWonActions.LOAD_EXPORT_LIST,
    }),

    async exportWon () {
      try {
        this.isLoading = true
        await this.loadExportList()
        this.isLoading = false

        const filename = [
          formatDate(new Date(), 'yyyy-MM-dd'),
          'won'
        ].join('-')

        downloadCsvFile(this.wonBlob, filename)
      } catch (e) {
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
        console.error(e)
      }
    },

    getTemplateCells (item) {
      const formatCurrencyCsv = (amount, currency) => {
        return this.$fc(amount, currency).replace(/,/g, '.')
      }

      switch (this.template) {
        case IMPACT_WON_LIST_TEMPLATES.invoices:
          return [formatCurrencyCsv(item.invoiceTotal, item.currency)]

        case IMPACT_WON_LIST_TEMPLATES.pickups:
          return [formatCurrencyCsv(item.salePrice, item.currency)]

        default:
          return []
      }
    },

    getWinnerName (item) {
      if (item.lookingForWinner) {
        return this.$t('LOOKING_FOR_WINNER_MSG')
      }

      if (item.participation.accountId === this.platformId) {
        return this.$t('DIRECT_PURCHASE_MSG')
      }

      if (item.participation.id) {
        return item.participation.fullName
      }

      return this.$t('COMMON.SYMBOLS.MDASH')
    },
  }
}
</script>

<style lang="scss" scoped>
.won-export {
  background: none;
  border: none;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.5;
  color: $color-sys-info;
  transition: color 150ms;

  &:hover,
  &:disabled {
    color: rgba($color-sys-info, 0.8);
  }
}
</style>

<i18n>
{
  "en": {
    "LOADING_MSG": "Loading…",
    "EXPORT_BTN": "Export",
    "EXPORT_TABLE_HEAD_BASE": "Lot ID,Car,VIN,Winner,Impact Login,Location",
    "EXPORT_TABLE_HEAD_INVOICES": "Invoice",
    "EXPORT_TABLE_HEAD_PICKUPS": "Price",
    "LOOKING_FOR_WINNER_MSG": "Looking For Winner",
    "DIRECT_PURCHASE_MSG": "Direct Purchase"
  },
  "ka": {
    "LOADING_MSG": "იტვირთება…",
    "EXPORT_BTN": "გადმოწერა",
    "EXPORT_TABLE_HEAD_BASE": "ლოტის ID,ავტომობილი,VIN,მოგებული,Impact-ზე შესვლა,ლოკაცია",
    "EXPORT_TABLE_HEAD_INVOICES": "ინვოისი",
    "EXPORT_TABLE_HEAD_PICKUPS": "ფასი",
    "LOOKING_FOR_WINNER_MSG": "დაკარგული",
    "DIRECT_PURCHASE_MSG": "პირდაპირ ნაყიდი"
  },
  "ru": {
    "LOADING_MSG": "Загрузка…",
    "EXPORT_BTN": "Экспорт",
    "EXPORT_TABLE_HEAD_BASE": "Лот ID,Авто,VIN,Победитель,Логин Impact,Локация",
    "EXPORT_TABLE_HEAD_INVOICES": "Счёт",
    "EXPORT_TABLE_HEAD_PICKUPS": "Цена",
    "LOOKING_FOR_WINNER_MSG": "Поиск победителя",
    "DIRECT_PURCHASE_MSG": "Прямая покупка"
  },
  "uk": {
    "LOADING_MSG": "Завантаження…",
    "EXPORT_BTN": "Експорт",
    "EXPORT_TABLE_HEAD_BASE": "Лот ID,Авто,VIN,Переможець,Логін Impact,Локація",
    "EXPORT_TABLE_HEAD_INVOICES": "Рахунок",
    "EXPORT_TABLE_HEAD_PICKUPS": "Ціна",
    "LOOKING_FOR_WINNER_MSG": "Пошук переможця",
    "DIRECT_PURCHASE_MSG": "Пряма покупка"
  }
}
</i18n>

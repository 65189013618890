<template>
  <div class="won-list-head impact-won-cmn__row">
    <span class="won-list-head__cell">
      {{ $t('CAR_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('WINNER_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('LOCATION_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      <template v-if="isInvoiceTemplate">
        {{ $t('INVOICE_HEAD_CELL') }}
      </template>

      <template v-else>
        {{ $t('PRICE_HEAD_CELL') }}
      </template>
    </span>
  </div>
</template>

<script>
import { IMPACT_WON_LIST_TEMPLATES } from '../constants'

export default {
  name: 'won-list-head',

  props: {
    template: {
      type: String,
      default: IMPACT_WON_LIST_TEMPLATES.invoices,
      validator (value) {
        return Object.values(IMPACT_WON_LIST_TEMPLATES).includes(value)
      },
    },
  },

  computed: {
    isInvoiceTemplate () {
      return this.template === IMPACT_WON_LIST_TEMPLATES.invoices
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/impact-won.scss";

.won-list-head {
  padding: 2em 3em 0;

  &__cell {
    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "CAR_HEAD_CELL": "CAR",
    "WINNER_HEAD_CELL": "WINNER",
    "LOCATION_HEAD_CELL": "LOCATION",
    "INVOICE_HEAD_CELL": "INVOICE",
    "PRICE_HEAD_CELL": "PRICE"
  },
  "ka": {
    "CAR_HEAD_CELL": "ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘ",
    "WINNER_HEAD_CELL": "ᲛᲝᲒᲔᲑᲣᲚᲘ",
    "LOCATION_HEAD_CELL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "INVOICE_HEAD_CELL": "ᲘᲜᲕᲝᲘᲡᲘ",
    "PRICE_HEAD_CELL": "ᲤᲐᲡᲘ"
  },
  "ru": {
    "CAR_HEAD_CELL": "АВТО",
    "WINNER_HEAD_CELL": "ПОБЕДИТЕЛЬ",
    "LOCATION_HEAD_CELL": "ЛОКАЦИЯ",
    "INVOICE_HEAD_CELL": "СЧËТ",
    "PRICE_HEAD_CELL": "ЦЕНА"
  },
  "uk": {
    "CAR_HEAD_CELL": "АВТО",
    "WINNER_HEAD_CELL": "ПЕРЕМОЖЕЦЬ",
    "LOCATION_HEAD_CELL": "ЛОКАЦІЯ",
    "INVOICE_HEAD_CELL": "РАХУНОК",
    "PRICE_HEAD_CELL": "ЦІНА"
  }
}
</i18n>

<template>
  <list-params
    class="won-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <div class="won-list-params__row">
        <label class="list-params-row__label">
          {{ $t('DEALER_LBL') }}
        </label>

        <dealer-select
          v-model="form.identity"
          class="won-list-params__dealer"
        />
      </div>

      <div class="won-list-params__row">
        <label class="list-params-row__label">
          {{ $t('IMPACT_LOGIN_LBL') }}
        </label>

        <ui-select
          v-model="form.login"
          fill="frame"
          look="secondary"
          class="won-list-params__copart-login"
          :options="managedLoginsOptions"
          :placeholder="$t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
          :search-placeholder="$t('COMMON.SELECT_FIELD.SEARCH_PH')"
          has-filter
        />
      </div>

      <list-params-period
        class="won-list-params__row"
        :label="$t('SALE_PERIOD_LBL')"
        :start-date.sync="form.minSoldAt"
        :end-date.sync="form.maxSoldAt"
        show-icon
        auto-close
      />

      <list-params-flags
        class="won-list-params__row"
        label=" "
        :value="{
          isLookingForWinner: {
            label: $t('FLAG_LOOKING_FOR_WINNER_LBL'),
            value: form.isLookingForWinner,
          }
        }"
        @input="form[$event.key] = $event.value"
      />
    </template>
  </list-params>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'
import {
  ListParams,
  ListParamsFlags,
  ListParamsPeriod
} from 'Common/ListParams'
import DealerSelect from 'Common/DealerSelect'

import { mapGetters } from 'vuex'
import { impactWonGetters } from '../store/types'

export default {
  name: 'won-list-params',
  components: {
    UiSelect,
    ListParams,
    ListParamsFlags,
    ListParamsPeriod,
    DealerSelect,
  },

  props: {
    value: { type: Object, required: true },
  },

  data: _ => ({
    form: {
      isLookingForWinner: false,
      identity: '',
      minSoldAt: '',
      maxSoldAt: '',
      login: ''
    },
  }),

  computed: {
    ...mapGetters('ui/impact-won', {
      managedLogins: impactWonGetters.MANAGED_LOGINS,
    }),

    managedLoginsOptions () {
      return this.managedLogins.map(value => ({
        value,
        label: value
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/ListParams/styles/list-params-row.scss";

</style>

<i18n>
{
  "en": {
    "DEALER_LBL": "BY DEALER",
    "SALE_PERIOD_LBL": "BY SALE PERIOD",
    "FLAG_LOOKING_FOR_WINNER_LBL": "Looking for winner",
    "IMPACT_LOGIN_LBL": "BY IMPACT LOGIN"
  },
  "ka": {
    "DEALER_LBL": "ᲓᲘᲚᲔᲠᲘᲡ ᲛᲘᲮᲔᲓᲕᲘᲗ",
    "SALE_PERIOD_LBL": "ᲒᲐᲧᲘᲓᲕᲘᲡ ᲞᲔᲠᲘᲝᲓᲘᲗ",
    "FLAG_LOOKING_FOR_WINNER_LBL": "დაკარგული",
    "IMPACT_LOGIN_LBL": "IMPACT-ᲖᲔ ᲨᲔᲡᲕᲚᲐ ᲛᲘᲮᲔᲓᲕᲘᲗ"
  },
  "ru": {
    "DEALER_LBL": "ПО ДИЛЕРУ",
    "SALE_PERIOD_LBL": "ПО ПЕРИОДУ ПРОДАЖИ",
    "FLAG_LOOKING_FOR_WINNER_LBL": "Поиск победителя",
    "IMPACT_LOGIN_LBL": "ПО ЛОГИНУ IMPACT"
  },
  "uk": {
    "DEALER_LBL": "ЗА ДИЛЕРОМ",
    "SALE_PERIOD_LBL": "ЗА ПЕРІОДОМ ПРОДАЖУ",
    "FLAG_LOOKING_FOR_WINNER_LBL": "Пошук переможця",
    "IMPACT_LOGIN_LBL": "ЗА ЛОГІНОМ IMPACT"
  }
}
</i18n>

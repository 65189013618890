<template>
  <div class="won-list-item">
    <div class="impact-won-cmn__row">
      <won-vehicle-cell :vehicle="item.vehicle" />

      <won-user-cell
        :item="item"
        :template="template"
      />

      <div class="cells-cmn__cell">
        <span class="cells-cmn__str cells-cmn__str_sec">
          {{ item.location }}
        </span>
      </div>

      <div class="cells-cmn__cell">
        <span class="cells-cmn__str cells-cmn__str_sec">
          <template v-if="isInvoiceTemplate">
            {{ $fc(item.invoiceTotal, item.currency) }}
          </template>

          <template v-else>
            {{ $fc(item.salePrice, item.currency) }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import WonVehicleCell from './WonVehicleCell'
import WonUserCell from './WonUserCell'

import { IMPACT_WON_LIST_TEMPLATES } from '../constants'
import { ImpactWinner } from 'Models/ImpactWinner'

export default {
  name: 'won-list-item',
  components: {
    WonVehicleCell,
    WonUserCell
  },

  props: {
    item: { type: ImpactWinner, required: true },
    template: {
      type: String,
      default: IMPACT_WON_LIST_TEMPLATES.invoices,
      validator (value) {
        return Object.values(IMPACT_WON_LIST_TEMPLATES).includes(value)
      },
    },
  },

  computed: {
    isInvoiceTemplate () {
      return this.template === IMPACT_WON_LIST_TEMPLATES.invoices
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";
@import "../styles/impact-won.scss";

.won-list-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);
}
</style>

<template>
  <list-grouped
    class="won-list"
    :list="list"
    :list-total-count="totalCount"
    :group-by-date-field="groupByField"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="IMPACT_WON_PAGE_LIMIT"
    :load-more="loadMore"
    :reset-on-change="template"
    :head-component="WonListHead"
    :head-component-props="{ template }"
    :item-component="WonListItem"
    :item-component-props="{ template }"
    :item-skeleton-component="WonListItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  >
    <won-export
      slot="actions"
      :template="template"
    />

    <div
      class="won-list__empty"
      slot="no-list-msg"
    >
      <p class="won-list__empty-msg">
        {{ $t('NO_ITEMS_MSG') }}
      </p>
    </div>
  </list-grouped>
</template>

<script>
import ListGrouped from 'Common/ListGrouped'

import WonListItem from './WonListItem'
import WonListItemSkeleton from './WonListItemSkeleton'
import WonListHead from './WonListHead'
import WonExport from './WonExport'

import { mapGetters } from 'vuex'
import { impactWonGetters } from '../store/types'

import {
  IMPACT_WON_LIST_TEMPLATES,
  IMPACT_WON_PAGE_LIMIT
} from '../constants'

import { validateArrayOf } from 'Models/modelUtils'
import { ImpactWinner } from 'Models/ImpactWinner'

export default {
  name: 'won-list',
  components: {
    ListGrouped,
    WonExport
  },

  props: {
    list: {
      type: Array,
      required: true,
      validator: validateArrayOf(ImpactWinner),
    },

    loadMore: {
      type: Function,
      required: true,
    },

    template: {
      type: String,
      default: IMPACT_WON_LIST_TEMPLATES.invoices,
      validator (value) {
        return Object.values(IMPACT_WON_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      WonListItem,
      WonListItemSkeleton,
      WonListHead,

      IMPACT_WON_PAGE_LIMIT,
    }
  },

  computed: {
    ...mapGetters('ui/impact-won', {
      isLoading: impactWonGetters.IS_LOADING,
      isNextLoading: impactWonGetters.IS_NEXT_LOADING,
      totalCount: impactWonGetters.TOTAL_COUNT,
    }),

    groupByField () {
      return this.template === IMPACT_WON_LIST_TEMPLATES.pickups
        ? 'saleDate'
        : 'invoiceDate'
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/impact-won.scss";

.won-list {
  --list-padding-side: 3em;

  /deep/ &__empty-btn {
    margin-top: 1em;

    .ui-button__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
    }

    &-icon {
      display: block;
      margin-left: 1em;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "NO_ITEMS_MSG": "No items here yet"
  },
  "ka": {
    "NO_ITEMS_MSG": "სია ცარიელია"
  },
  "ru": {
    "NO_ITEMS_MSG": "Нет элементов для отображения"
  },
  "uk": {
    "NO_ITEMS_MSG": "Нема елементів для відображення"
  }
}
</i18n>

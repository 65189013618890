<template>
  <div class="impact-won">
    <page-subnav class="impact-won__subnav">
      <won-list-params
        class="impact-won__subnav-action"
        :value="{
          identity: $route.query.identity || '',
          login: $route.query.login || '',
          isLookingForWinner: $route.query.isLookingForWinner === 'true',
          minSoldAt: $route.query.minSoldAt || '',
          maxSoldAt: $route.query.maxSoldAt || '',
        }"
        @input="onListParamsChange"
      />

      <subnav-search
        class="impact-won__subnav-action impact-won__subnav-action_ml"
        :value="$route.query.search"
        @input="onSearch"
      />

      <template slot="right">
        <router-link
          class="impact-won__tab"
          active-class="impact-won__tab_active"
          :to="{
            params: { tab: IMPACT_WON_URL_PARAM_TABS.invoices },
          }"
        >
          {{ $t('INVOICES_TAB') }}
        </router-link>

        <router-link
          class="impact-won__tab"
          active-class="impact-won__tab_active"
          :to="{
            params: { tab: IMPACT_WON_URL_PARAM_TABS.pickups },
          }"
        >
          {{ $t('PICKUPS_TAB') }}
        </router-link>
      </template>
    </page-subnav>

    <won-list
      class="impact-won__list"
      :list="list"
      :template="template"
      :load-more="loadMoreWrapper"
      @update-list-ask="loadList"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'

import WonList from './components/WonList'
import WonListParams from './components/WonListParams'

import { mapActions, mapGetters } from 'vuex'
import { impactWonActions, impactWonGetters } from './store/types'

import { IMPACT_WON_LIST_TEMPLATES } from './constants'
import { IMPACT_WON_URL_PARAM_TABS } from 'Constants/extensionWonUrlParams'

import { showError } from 'Utils/notifications'

import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

const TAB_TO_TEMPLATE_MAP = Object.freeze({
  [IMPACT_WON_URL_PARAM_TABS.invoices]: IMPACT_WON_LIST_TEMPLATES.invoices,
  [IMPACT_WON_URL_PARAM_TABS.pickups]: IMPACT_WON_LIST_TEMPLATES.pickups,
})

const TAB_TO_LOAD_ACTION_MAP = Object.freeze({
  [IMPACT_WON_URL_PARAM_TABS.invoices]:
    impactWonActions.LOAD_INVOICES_LIST,
  [IMPACT_WON_URL_PARAM_TABS.pickups]:
    impactWonActions.LOAD_PICKUPS_LIST,
})

export default {
  name: 'impact-won',
  components: {
    PageSubnav,
    SubnavSearch,
    WonList,
    WonListParams,
  },

  data () {
    return {
      template: TAB_TO_TEMPLATE_MAP[IMPACT_WON_URL_PARAM_TABS.pickups],

      IMPACT_WON_URL_PARAM_TABS
    }
  },

  computed: {
    ...mapGetters('ui/impact-won', {
      list: impactWonGetters.LIST,
      isLoading: impactWonGetters.IS_LOADING,
    }),

    metaTitle () {
      const tabTitles = {
        [IMPACT_WON_URL_PARAM_TABS.invoices]: this.$t('META_TITLE_INVOICES'),
        [IMPACT_WON_URL_PARAM_TABS.pickups]: this.$t('META_TITLE_PICKUPS'),
      }
      return tabTitles[this.$route.params.tab]
    },

    loadMoreWrapper () {
      return async () => {
        await this.loadMore(this.listPayload)
      }
    },

    listPayload () {
      return {
        filter: {
          search: this.$route.query.search || undefined,
          identity: this.$route.query.identity || undefined,
          lookingForWinner: this.$route.query.isLookingForWinner === 'true' || undefined,
          minSoldAt: this.$route.query.minSoldAt || undefined,
          maxSoldAt: this.$route.query.maxSoldAt || undefined,
        }
      }
    }
  },

  watch: {
    '$route.params.tab': {
      immediate: true,
      handler (tab, oldTab) {
        if (isEqual(tab, oldTab)) return
        this.onListParamsChange(this.$route.query)
      }
    },
  },

  created () {
    this.loadManagedLogins()
  },

  methods: {
    ...mapActions('ui/impact-won', {
      loadListAction: impactWonActions.LOAD_LIST,
      loadMore: impactWonActions.LOAD_MORE,
      setIsLoading: impactWonActions.SET_IS_LOADING,
      loadManagedLogins: impactWonActions.LOAD_MANAGED_LOGINS,
    }),

    onListParamsChange (input) {
      const query = { ...this.$route.query }

      query.identity = input.identity || undefined
      query.isLookingForWinner = input.isLookingForWinner || undefined

      query.minSoldAt = input.minSoldAt || undefined
      query.maxSoldAt = input.maxSoldAt || undefined

      query.login = input.login || undefined
      query.search = query.login || query.search

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }

      this.loadList()
    },

    async loadList () {
      try {
        const tab = this.$route.params.tab
        await this.loadListAction({
          action: TAB_TO_LOAD_ACTION_MAP[tab],
          opts: this.listPayload
        })

        this.template = TAB_TO_TEMPLATE_MAP[tab]
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },

    onSearch (value) {
      const querySearch = this.$route.query.search || ''
      const search = value.trim()

      const MIN_SEARCH_LENGTH = 5
      const isValidLength = search.length === 0 ||
        search.length >= MIN_SEARCH_LENGTH

      if (search === querySearch || !isValidLength) return

      this.setIsLoading(true)
      this.applySearchDebounced(search)
    },

    applySearchDebounced: debounce(async function (value) {
      this.$router.push({
        query: {
          search: value || undefined,
          login: undefined
        }
      })
      this.loadList()
    }, 500),
  },

  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.impact-won {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__create-btn {
    & /deep/ .ui-button__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-icon {
      display: block;
      margin-left: 1em;
      font-size: 1.2em;
    }
  }

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }

    &_highlighted {
      color: $color-flag-is-error;
    }

    &_highlighted.impact-won__tab_active {
      box-shadow: 0 -1px 0 $color-flag-is-error inset !important;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "INVOICES_TAB": "TO BE PAID",
    "PICKUPS_TAB": "TO BE PICKED UP",
    "META_TITLE_INVOICES": "To Be Paid",
    "META_TITLE_PICKUPS": "To Be Picked Up",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the won list. Please try again later or contact the system owner."
  },
  "ka": {
    "INVOICES_TAB": "ᲒᲐᲓᲐᲡᲐᲮᲓᲔᲚᲘ",
    "PICKUPS_TAB": "ᲐᲡᲐᲧᲕᲐᲜᲘ",
    "META_TITLE_INVOICES": "გადასახდელი",
    "META_TITLE_PICKUPS": "ასაყვანი",
    "LIST_FETCH_FAILED_NOTIFY": "მოგებული ვერ ჩაიტვირთა, მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "INVOICES_TAB": "К ОПЛАТЕ",
    "PICKUPS_TAB": "НУЖНО ЗАБРАТЬ",
    "META_TITLE_INVOICES": "К Оплате",
    "META_TITLE_PICKUPS": "Нужно Забрать",
    "LIST_FETCH_FAILED_NOTIFY": "Не удается загрузить список выигранных лотов. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "INVOICES_TAB": "ДО СПЛАТИ",
    "PICKUPS_TAB": "ПОТРІБНО ЗАБРАТИ",
    "META_TITLE_INVOICES": "До Сплати",
    "META_TITLE_PICKUPS": "Потрібно Забрати",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдається завантажити список виграних лотів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
